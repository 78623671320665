body {
    font-family: 'Poppins', sans-serif;
}

a {
    color: #F48C04;
}

a:hover {
    color: #FCBB0C;
}

.navbarHead {
    width: 100px !important;
}

.navbar {
    background-color: white;
}

.wrapper {
    position: relative;
    width: 100px;
    height: 100px;
}

#slide {
    position: absolute;
    left: -400px;
    width: 100px;
    height: 100px;
    transition: 1s;
}

.wrapper2 #slide {
    transition: 1s;
    left: 0;
}

.profilePhoto {
    aspect-ratio: 1/1;
    border-radius: 500px;
}

.profileCard {
    margin: 15px 0px;
}

.position {
    color: #F48C04;
    margin-top: 10px;
    font-size: 20px;
}

.pripolpara {
    background-color: white;
    margin: 20px 0px;
    padding: 30px;
    border-radius: 50px;
}

.slide {
    width: 100%;
}

.overCard {
    background-color: black;
    padding: 0px;
    border: none;
    border-radius: 0px;
}

.overlayImg {
    opacity: 80% !important;
}

.CA {
    background-color: white;
    margin: 20px 0px;
    padding: 30px;
    border-radius: 50px;
}

.CH {
    height: calc(100vh - 56px);
}

.devdes {
    margin: auto;
    background-color: #FCBB0C;
    padding: 40px 25px 25px 25px;
    border-radius: 25px;
}

.chimg {
    width: 70px;
}

.chimg2 {
    margin: 50px 20px 10px 20px;
    width: 100px;
}

.chimg3 {
    width: 70px;
    float: right;
}

.churchName {
    float: right;
    margin-top: 22px;
}

.eventModule {
    border: 2px solid black;
    padding: 20px;
    border-radius: 10px;
}

.churchevents {
    border-radius: 50px;
    padding: 20px;
}

.sideCard {
    background-color: white;
    border-radius: 5px;
    margin-bottom: 25px;
}
.cedImg {
    padding: 15px;
    border-radius: 25px!important;
}
.cedTextBox {
    padding: 15px;
}

.footer {
    background-color: #9f6110;
}
.footerObj {
    justify-content: space-evenly;
    display: flex;
    padding: 15px;
}
.churchHeader {
    margin-top: 20px;
}